import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { wobbleAnimation, circleAnimation, rectangleAnimation } from '../styles/animations'
import { screens } from '../../tailwind'

const Wrapper = styled.svg`
  ${tw`absolute`};
  stroke: currentColor;
  color: ${props => props.stroke};
  width: ${props => props.svgWidth};
  fill: ${props => props.fill};
  left: ${props => props.left};
  top: ${props => props.top};
  transform: rotate(${props => props.rotate}) scale(${props => props.scale});
  z-index: -1;

  ${props =>
    props.mobile &&
    css`
      @media screen and (max-width: ${screens.md}) {
        left: ${props.mobile.left};
        top: ${props.mobile.top};
        width: ${props.mobile.width};
      }
    `}
`

const icons = {
  scaleneTriangle: {
    shape: (
      <path fillRule="evenodd" clipRule="evenodd" d="M172.57 222.056l589.239-46.038L87.028 497.822l85.542-275.766z" />
    ),
    viewBox: '0 0 773 498',
    animation: wobbleAnimation,
    duration: '50s',
  },
  triangle: {
    shape: <path fillRule="evenodd" clipRule="evenodd" d="M283.77 0l226.304 382.5L0 491.062 283.77 0z" />,
    viewBox: '0 0 511 492',
    animation: wobbleAnimation,
    duration: '100s',
  },
  circle: {
    shape: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.5 371C287.949 371 371 287.949 371 185.5S287.949 0 185.5 0 0 83.051 0 185.5 83.051 371 185.5 371z"
      />
    ),
    viewBox: '0 0 371 371',
    animation: circleAnimation,
    duration: '70s',
  },
  rectangle: {
    shape: <path d="M0 .414h592v153H0z" />,
    viewBox: '0 0 592 154',
    animation: rectangleAnimation,
    duration: '35s',
  },
}

const SVG = ({ stroke, fill, width, icon, left, top, mobile, rotate, scale, hiddenMobile }) => (
  <Wrapper
    viewBox={icons[icon].viewBox}
    stroke={stroke}
    fill={fill}
    svgWidth={`${width}`}
    left={left}
    top={top}
    mobile={mobile}
    rotate={rotate}
    scale={scale}
    hiddenMobile={hiddenMobile}
    animation={icons[icon].animation}
    duration={icons[icon].duration}
  >
    {icons[icon].shape}
  </Wrapper>
)

export default SVG

SVG.propTypes = {
  stroke: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  left: PropTypes.string,
  top: PropTypes.string,
  mobile: PropTypes.oneOfType(PropTypes.object, PropTypes.null),
  rotate: PropTypes.string,
  scale: PropTypes.string,
  hiddenMobile: PropTypes.bool,
}

SVG.defaultProps = {
  stroke: 'transparent',
  width: 8,
  fill: 'none',
  left: '0%',
  top: '0%',
  mobile: null,
  rotate: '0',
  scale: '1',
  hiddenMobile: false,
}
