import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import tw from 'tailwind.macro'
import { colors, screens } from '../../tailwind'

// Components
import Layout from '../components/Layout'
import Menu from '../components/Menu'
import ProjectCard from '../components/ProjectCard'
import Footer from '../components/Footer'

// Elements
import Inner from '../elements/Inner'
import { BigTitle, Subtitle } from '../elements/Titles'

const HeroWrapper = styled(Inner)`
  ${tw`w-full px-8 md:px-16 pt-32`};
  min-height: 30rem;

  @media (min-width: ${screens.lg}) {
    min-height: 40rem;
  }
`

const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 20rem;
  @media (max-width: ${screens.xl}) {
    grid-gap: 3rem;
    margin-bottom: 18rem;
  }
  @media (max-width: ${screens.lg}) {
    grid-gap: 2rem;
    margin-bottom: 16rem;
  }

  & > * {
    width: 100%;
    min-width: auto;

    @media (min-width: ${screens.lg}) {
      width: 45%;
      min-width: 500px;

      &:nth-child(even) {
        margin-left: auto;
      }

      &:not(:first-child) {
        margin-top: -16rem;
      }
    }
  }
`

const theme = {
  menu: colors.black,
  menuHover: colors['am-green-ally'],
  accent: colors['am-green'],
}

const Index = ({ data }) => {
  const images = {}
  data.projects.images.forEach(({ name, childImageSharp }) => {
    images[name] = childImageSharp
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Menu hideLogo />
        <HeroWrapper>
          <BigTitle>Hi, I’m Alex.</BigTitle>
          <Subtitle>
            I’m a <strong>user experience designer</strong> that enjoys making life better through design.
          </Subtitle>
        </HeroWrapper>
        <Inner>
          <ProjectsWrapper>
            <ProjectCard
              title="Platform-wide orchestration"
              link="/orchestration"
              linkText="LINK'EM UP"
              linkColor={colors['am-purple-ally']}
              imageUrl={images.orchestration.fluid}
              svgProps={{
                icon: 'scaleneTriangle',
                width: '180%',
                fill: colors['am-purple'],
                left: '-50%',
                top: '-30%',
              }}
            >
              Create automated processes out of data and ML models.
            </ProjectCard>
            <ProjectCard
              title="IBM Pollinate"
              link="/pollinate"
              linkText="FIND YOUR PASSION"
              linkColor={colors['am-yellow-ally']}
              imageUrl={images.pollinate.fluid}
              svgProps={{
                icon: 'circle',
                width: '80%',
                fill: colors['am-yellow'],
                left: '30%',
                top: '8%',
              }}
            >
              Bringing culture and creativity to IBMers.
            </ProjectCard>
            <ProjectCard
              title="Platform-wide search"
              link="/search"
              linkText="SHOW ME THE DATA"
              linkColor={colors['am-green-ally']}
              imageUrl={images.search.fluid}
              svgProps={{
                icon: 'rectangle',
                width: '120%',
                fill: colors['am-green'],
                left: '-20%',
                top: '33%',
              }}
            >
              Search for anything, anytime, anywhere.
            </ProjectCard>
            <ProjectCard
              title="Temp°"
              link="/tempo"
              linkText="TURN UP"
              linkColor={colors['am-blue-ally']}
              imageUrl={images.tempo.fluid}
              svgProps={{
                icon: 'triangle',
                width: '80%',
                fill: colors['am-blue'],
                left: '25%',
                top: '-5%',
              }}
            >
              Turn weather data into music.
            </ProjectCard>
            <ProjectCard
              title="MassArt.edu"
              link="/massart"
              linkText="MAKE YOUR MARK"
              linkColor={colors['am-orange-ally']}
              imageUrl={images.scholarjet.fluid}
              svgProps={{
                icon: 'triangle',
                width: '80%',
                fill: colors['am-orange'],
                left: '10%',
                top: '-15%',
                rotate: '20deg',
                scale: '2, 1',
              }}
            >
              An art school allows students to make their mark.
            </ProjectCard>
          </ProjectsWrapper>
        </Inner>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Index

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const pageQuery = graphql`
  {
    projects: allFile(filter: { relativePath: { glob: "projects/**/*" } }) {
      images: nodes {
        name
        childImageSharp {
          fluid(maxWidth: 980) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
