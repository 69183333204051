import { keyframes, css } from 'styled-components'

const wave = keyframes`
  0% {
    d: path("M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  50% {
    d: path("M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  100% {
    d: path("M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
`

const wobble = keyframes`
  from {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(5deg);
  }
`

const growFromTopRight = keyframes`
  from {
    transform: translate(0, 0) scale(0.8);
  }
  to {
    transform: translate(-15vw, 10vw) scale(1.1);
  }
`

const moveRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10%);
  }
`

export const waveAnimation = length => css`
  animation: ${wave} ${length} linear infinite alternate;
`

export const wobbleAnimation = length => css`
  animation: ${wobble} ${length} ease infinite alternate;
`

export const circleAnimation = length => css`
  animation: ${growFromTopRight} ${length} ease infinite alternate;
`

export const rectangleAnimation = length => css`
  animation: ${moveRight} ${length} ease infinite alternate;
`
