import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import Img from 'gatsby-image'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import { ArrowLink } from '../elements/Links'
import SVG from './SVG'

const Wrapper = styled.div`
  ${tw`relative pb-16 pt-12 px-12 text-grey-dark`};
  background: ${props => props.bg};
`

const SvgContainer = styled.div`
  ${tw`absolute pin`};
  z-index: -1;
`

const ProjectContainer = styled.div`
  ${tw`z-10`};
`

const ImageContainer = styled(AniLink)`
  ${tw`inline-block pb-8 md:pb-16`};
  width: 100%;
  max-width: 580px;
  transform: translateX(-48px);
`

const Text = styled.div`
  ${tw`pb-6 md:pb-8 text-grey-darker text-base md:text-lg`};
`

const Title = styled.div`
  ${tw`text-grey-darkest text-2xl md:text-3xl xl:text-4xl tracking-wide font-bold pb-4`};
`

const ProjectCard = ({ title, link, linkText, linkColor, imageUrl, svgProps, children }) => (
  <Wrapper>
    <SvgContainer>
      <SVG {...svgProps} />
    </SvgContainer>
    <ProjectContainer>
      <ImageContainer cover to={link} bg={svgProps.fill} duration={2}>
        <Img fluid={imageUrl} />
      </ImageContainer>
      <Title>{title}</Title>
      <Text>{children}</Text>
    </ProjectContainer>
    <ArrowLink cover to={link} bg={svgProps.fill} color={linkColor}>
      {linkText}
    </ArrowLink>
  </Wrapper>
)

export default ProjectCard

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  imageUrl: PropTypes.object.isRequired,
  svgProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}
