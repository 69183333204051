import styled from 'styled-components'
import tw from 'tailwind.macro'

export const BigTitle = styled.h1`
  ${tw`text-5xl lg:text-6xl text-black tracking-wide font-black`};
  margin-bottom: 3rem;
`

export const Title = styled.h1`
  ${tw`mb-4 lg:mb-8 text-4xl text-black font-bold`};
`

export const Subtitle = styled.p`
  ${tw`text-2xl lg:text-4xl text-black mt-8 xl:w-3/5 xxl:w-3/4`};
`
